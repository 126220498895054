<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template #activator="{ on }">
			<v-btn text small rounded color="warning" v-on="on">
				{{ $t('cv.addNewMasc') }}
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('cv.addNewLanguage') }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-autocomplete
						v-model="selectedLanguage"
						:items="selectableLanguages"
						:loading="loading || loadingParent"
						:search-input.sync="search"
						:filter="customFilter"
						hide-details
						hide-selected
						item-text="name"
						item-value="id"
						:label="`${$t('search.search')}...`"
						return-object
					>
						<template #no-data>
							<v-list-item v-if="search == null">
								<v-list-item-title>
									<strong>{{ $t('search.search') }}</strong>
								</v-list-item-title>
							</v-list-item>
							<v-list-item v-else-if="adquiredLanguage(search)">
								<v-list-item-title>{{ $t('cv.alreadyAdded') }}</v-list-item-title>
							</v-list-item>
							<v-list-item v-else>
								<v-list-item-content>
									<v-list-item-title>
										<strong>{{ search }}</strong> not found
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
						<template #item="{ item }">
							<v-list-item-content>
								<v-list-item-title v-text="item.name" @click.stop="callPrepareLanguage(item)" />
							</v-list-item-content>
						</template>
					</v-autocomplete>
				</v-form>
				<template v-for="language in newLanguages">
					<v-card class="my-1" flat :key="`language${language.id}`" v-if="!language.added" rounded="xl">
						<v-card-title>
							{{ language.name }}
						</v-card-title>
						<v-card-text>
							<v-select
								v-model="language.level"
								:label="$t('cv.languageLevel')"
								:items="availableLanguageLevels"
								item-text="name"
								item-value="key"
								:rules="rules.level"
								return-object
								outlined
							/>
						</v-card-text>
						<v-card-actions>
							<v-btn text color="primary" @click="callAddLanguage(language)">
								{{ $t('settings.add') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</template>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text @click="switchEditMode()">
					{{ $t('search.close') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function (letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function (match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'CVLanguagesAdd',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			search: null,
			selectedLanguage: null,
			newLanguages: [],
			rules: {
				level: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableLanguageLevels.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported'),
				],
			},
		}
	},
	computed: {
		selectableLanguages() {
			return this.availableLanguages.filter((language) => !this.languages.map((s) => s.id).includes(language.id))
		},
		...mapGetters({
			availableLanguageLevels: 'data/availableLanguageLevels',
			availableLanguages: 'cv/availableLanguages',
			languages: 'cv/languages',
		}),
	},
	watch: {
		search(val) {
			if (val) this.queryAvailableLanguages(val)
		},
	},
	created() {
		this.fetchAvailableLanguageLevels()
		this.queryAvailableLanguages('')
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		queryAvailableLanguages(search) {
			this.loading = true
			this.fetchAvailableLanguages({ keywords: search }).then(() => {
				this.loading = false
			})
		},
		customFilter(item, queryText) {
			const text = removeAccents(item.name.toLowerCase())
			const searchText = removeAccents(queryText.toLowerCase())
			return text.includes(searchText)
		},
		adquiredLanguage(search) {
			return this.languages.map((language) => language.name).includes(search)
		},
		callPrepareLanguage(language) {
			this.newLanguages.push(language)
			this.selectedLanguage = null
			this.search = ''
		},
		callAddLanguage(language) {
			this.loading = true
			this.addLanguage({
				languageID: language.id,
				level: language.level,
			})
				.then(({ success }) => {
					if (success) {
						language.added = true
						this.selectedLanguage = null
						this.search = ''
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('cv', ['fetchAvailableLanguages', 'addLanguage']),
		...mapActions('data', ['fetchAvailableLanguageLevels']),
	},
}
</script>
